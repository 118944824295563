var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("h4", [
                    _c("i", { staticClass: "nav-icon icon-layers" }),
                    _vm._v(" Manager Role Editor")
                  ]),
                  _c("hr"),
                  _c("v-client-table", {
                    staticClass: "roles-width",
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.wtsData,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "budget",
                        fn: function(props) {
                          return _c("div", {}, [
                            _c("h6", { staticClass: "d-xs-block d-lg-none" }, [
                              _vm._v("Budget Table")
                            ]),
                            props.row.budget === 1
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_c("i", { staticClass: "fa fa-check" })]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "startend",
                        fn: function(props) {
                          return _c("div", {}, [
                            _c("h6", { staticClass: "d-xs-block d-lg-none" }, [
                              _vm._v("Category Dates Table")
                            ]),
                            props.row.startend === 1
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_c("i", { staticClass: "fa fa-check" })]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "authority",
                        fn: function(props) {
                          return _c("div", {}, [
                            _c("h6", { staticClass: "d-xs-block d-lg-none" }, [
                              _vm._v("dollar-sign Authority")
                            ]),
                            props.row.authority === 1
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_c("i", { staticClass: "fa fa-check" })]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "wts",
                        fn: function(props) {
                          return _c("div", {}, [
                            _c("h6", { staticClass: "d-xs-block d-lg-none" }, [
                              _vm._v("WTS Suspension")
                            ]),
                            props.row.wts === 1
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_c("i", { staticClass: "fa fa-check" })]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "edit",
                        fn: function(props) {
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-inline d-lg-none" },
                                [_vm._v("Edit")]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.editRole",
                                      modifiers: { editRole: true }
                                    }
                                  ],
                                  staticClass: "d-inline",
                                  attrs: { size: "sm", variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendInfo(
                                        props.row.user,
                                        props.row.budget,
                                        props.row.startend,
                                        props.row.authority,
                                        props.row.wts
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-edit" })]
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "editRole",
          attrs: {
            id: "editRole",
            title: "Edit Roles",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: { shown: _vm.clearErrors }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "1", "unchecked-value": "0" },
                      model: {
                        value: _vm.budget,
                        callback: function($$v) {
                          _vm.budget = $$v
                        },
                        expression: "budget"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm._v("Budget Table | "),
                        _vm.budget == 1
                          ? _c("span", [_c("strong", [_vm._v("Active")])])
                          : _vm._e(),
                        _vm.budget != 1
                          ? _c("span", [_c("strong", [_vm._v("Inactive")])])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "1", "unchecked-value": "0" },
                      model: {
                        value: _vm.startend,
                        callback: function($$v) {
                          _vm.startend = $$v
                        },
                        expression: "startend"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm._v("Category Dates Table | "),
                        _vm.startend == 1
                          ? _c("span", [_c("strong", [_vm._v("Active")])])
                          : _vm._e(),
                        _vm.startend != 1
                          ? _c("span", [_c("strong", [_vm._v("Inactive")])])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "1", "unchecked-value": "0" },
                      model: {
                        value: _vm.authority,
                        callback: function($$v) {
                          _vm.authority = $$v
                        },
                        expression: "authority"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm._v("dollar-sign Authorities | "),
                        _vm.authority == 1
                          ? _c("span", [_c("strong", [_vm._v("Active")])])
                          : _vm._e(),
                        _vm.authority != 1
                          ? _c("span", [_c("strong", [_vm._v("Inactive")])])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "1", "unchecked-value": "0" },
                      model: {
                        value: _vm.wts,
                        callback: function($$v) {
                          _vm.wts = $$v
                        },
                        expression: "wts"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm._v("WTS Suspension |  "),
                        _vm.wts == 1
                          ? _c("span", [_c("strong", [_vm._v("Active")])])
                          : _vm._e(),
                        _vm.wts != 1
                          ? _c("span", [_c("strong", [_vm._v("Inactive")])])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [
                  _vm._v(
                    "Error. Not authorized to update table. Please contact admin."
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }